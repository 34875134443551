import React, { useContext, useState, useEffect, useRef } from "react";
import { Editor } from "@toast-ui/react-editor";
import { Row, Col, Avatar, Input  } from "antd";

// import Editor from "rich-markdown-editor";
import { CommentContext } from "./MainLayout";
import { SEND_COMMENTS } from "./actions";
import { sendComment, uploadImage } from "./requests";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const randomInt = getRandomInt(100).toString()
const avatar = `https://blobcdn.com/blob.svg?seed=${randomInt}&extraPoints=${randomInt}`;
const CommentBox = () => {
  const { comments, dispatch } = useContext(CommentContext);
  const [pressed, setPressed] = useState([]);
  const [user, setUser] = useState(localStorage.getItem("user") || "Anonymous");

  const ALLOWED_KEYS = ["Control", "Enter"];
  const editorRef = useRef(null);

  const submitComment = async data => {
    let sendData = {
      comment: comments.comment
    };
    sendData.comment.push(data);
    const response = await sendComment(sendData);

    return response;
  };

  useEffect(() => {
    const handleKeyDown = event => {
      const { key } = event;

      if (ALLOWED_KEYS.includes(key) && !pressed.includes(key)) {
        setPressed(prevPressed => [...prevPressed, key]);
      }
    };
    const handleKeyUp = event => {
      const { key } = event;
      if (ALLOWED_KEYS.includes(key)) {
        setPressed([]);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    // if (!initialized) {
    //   setUser();
    // }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  });

  useEffect(() => {
    if (pressed.includes("Control") && pressed.includes("Enter")) {
      const con = editorRef.current.getInstance().getMarkdown();
      const time = Date.now();
      const data = {
        avatar: avatar,
        user: user,
        content: con,
        time: time
      };
      localStorage.setItem("user", user);
      dispatch({ type: SEND_COMMENTS, payload: data });
      editorRef.current.getInstance().setMarkdown("", true);
      submitComment(data);
    }
  }, [pressed]);

  return (
    <Row>
      <Col flex="2">
        <Row>
          <Input
            // value={localStorage.getItem("user") || "Anonymous"}
            type="text"
            value={user}
            onChange={e => setUser(e.target.value)}
          />
        </Row>
        <Row>
        <Avatar size={135} icon={<img src={avatar} />} />
        </Row>
      </Col>
      <Col flex="8">
        <Editor
          initialValue={localStorage.getItem("saved") || ""}
          previewStyle="vertical"
          height="25vh"
          initialEditType="wysiwyg"
          useCommandShortcut={true}
          usageStatistics={false}
          hooks={{
            addImageBlobHook: async (blob, callback) => {
              // const uploadedImageURL = uploadImage(blob);
              let result = ""
              try {
                result = await uploadImage(blob);
                console.log(result)
              } catch (err) {
                console.log('Upload Process failed', err);
              } finally {
                callback(result, "alt text");
                return false;
              }

            }
          }}
          // hideModeSwitch={true}
          ref={editorRef}
        />
      </Col>
    </Row>
    
  );
};

export default CommentBox;
