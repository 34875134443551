import React, { useContext } from "react";
import { Comment, Tooltip, List } from "antd";
import moment from "moment";
import { Viewer } from "@toast-ui/react-editor";


import { CommentContext } from "./MainLayout";

function timeConverter(time) {
  const post = moment(time);

  const layout = (
    <Tooltip title={post.format("YYYY-MM-DD HH:mm:ss")}>
      <span>{post.fromNow()}</span>
    </Tooltip>
  );
  return layout;
}

function markdownConverter(content) {
  //   let data = `
  //   # Welcome to StackEdit!

  // Hi! I'm your first Markdown file in **StackEdit**. If you want to learn about StackEdit, you can read me. If you want to play with Markdown, you can edit me. Once you have finished with me, you can create new files by opening the **file explorer** on the left corner of the navigation bar.
  // ![enter image description here](https://i.imgur.com/mHN5e9M.jpg)

  // # Files
  //   `;
  return <Viewer initialValue={content} usageStatistics={false} />;
  // return <ReactMarkdown source={content} />;
  // return <Editor readOnly={true} dark={true} defaultValue={content} />;
}

const Comments = () => {
  const { comments, dispatch } = useContext(CommentContext);

  return (
    <List
      className="comment-list"
      // header={`${data.length} replies`}
      itemLayout="horizontal"
      dataSource={comments.comment}
      renderItem={item => (
        <li>
          <Comment
            // actions={item.actions}
            author={item.user}
            avatar={item.avatar}
            content={markdownConverter(item.content)}
            datetime={timeConverter(item.time)}
          />
          {/* <Divider dashed /> */}
        </li>
      )}
    />
  );
};

export default Comments;
