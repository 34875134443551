import { SET_COMMENTS, SEND_COMMENTS, GET_COMMENTS } from "./actions";
function commentsReducer(
  state = {
    comment: []
  },
  action
) {
  switch (action.type) {
    case SET_COMMENTS:
      state = action.payload;
      return state;
    case SEND_COMMENTS:
      return {
        comment: [...state.comment, action.payload]
      };
    case GET_COMMENTS:
      return state;

    default:
      return state;
  }
}

export { commentsReducer };
