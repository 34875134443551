import React from "react";
import ReactDOM from "react-dom";

import MainLayout from "./MainLayout";
import { PusherProvider } from "@harelpls/use-pusher";
import {
  enable as enableDarkMode
} from 'darkreader';
enableDarkMode({
  brightness: 100,
  contrast: 90,
  sepia: 10,
});
const config = {
  // required config props
  clientKey: "96fde189527c21aa45ab",
  cluster: "ap3"
};
const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <PusherProvider {...config}>
      <MainLayout />
    </PusherProvider>
  </React.StrictMode>,
  rootElement
);
