import React, { useReducer, createContext, useEffect, useState } from "react";

import { Layout } from "antd";
import { getComments } from "./requests";
import { commentsReducer } from "./reducers";
import { SET_COMMENTS, SEND_COMMENTS } from "./actions";

import Comments from "./Comments";
import CommentBox from "./CommentBox";

import { useChannel, useEvent } from "@harelpls/use-pusher";
import "antd/dist/antd.css";
import 'codemirror/lib/codemirror.css';
import "@toast-ui/editor/dist/toastui-editor.css";

import "./styles.css";
const { Header, Content } = Layout;

const MainLayout = () => {
  const [initialized, setInitialized] = useState(false);
  const [comments, dispatch] = useReducer(commentsReducer, {
    comment: []
  });

  const channel = useChannel("chat");
  useEvent(channel, "new-comment", data => {
    if (comments.comment.some(e => e.time === data.time)) {
      /* vendors contains the element we're looking for */
    } else {
      console.log(data.time);
      console.log(comments.comment[comments.comment.length - 1].time);
      dispatch({ type: SEND_COMMENTS, payload: data });
      if (Notification && Notification.permission === "granted") {
        new Notification(`${data.user} ： ${data.content}`);
      }
    }

  });

  const getData = async () => {
    const response = await getComments();
    let result = { comment: [] };
    result.comment = response.data.comment;
    // result.comment = response.data.comment.slice(
    //   Math.max(response.data.comment.length - 5, 0)
    // );
    dispatch({ type: SET_COMMENTS, payload: result });

    setInitialized(true);
  };

  const setNotification = () => {
    if (Notification && Notification.permission !== "granted") {
      Notification.requestPermission(function (status) {
        if (Notification.permission !== status) {
          Notification.permission = status;
        }
      });
    }
  };

  useEffect(() => {
    if (!initialized) {
      setNotification();
      getData();
    }
  });
  useEffect(() => {
    const container = document.querySelector(".ant-list-items");
    if (typeof container !== "undefined" && container !== null) {
      container.scrollTop = container.scrollHeight;
    }
  }, [comments]);
  return (
    <CommentContext.Provider value={{ comments, dispatch }}>
      <Layout className="layout">
        <Content className="container is-fluid">
          {/* {comments.comment.map(c => (
                  <div> {c.content}</div>
                ))} */}
          <Comments />
        </Content>
        <Content className="container is-fluid">
          <CommentBox />
        </Content>
      </Layout>
    </CommentContext.Provider>
  );
};
export const CommentContext = createContext({
  comment: []
});
export default MainLayout;
