const APIURL = "./api";
export const getComments = async () => {
  let data = await fetch("./api", {
    method: "get",
    headers: { "Content-Type": "application/json" }
  });
  data = await data.json();
  return data;
};
export const sendComment = async comment => {
  if (comment.comment.length > 100) {
    comment.comment = comment.comment.slice(
      Math.max(comment.comment.length - 100, 0)
    );
  }

  const response = await fetch(
    "./api",
    {
      method: "post",
      body: JSON.stringify(comment),
      headers: { "Content-Type": "application/json" }
    }
  );
  const json = await response.json();
  // if (json.data.success) {
  //   updateList(newComment);
  // }
  return json;
};

const fileInput = document.querySelector("#fileInput");



async function uploadFile(data, filename) {
  // const API_ENDPOINT = "./api/" + files.name; 
  const API_ENDPOINT = "https://image_uploader-1-o9088742.deta.app/image-base64";



  let sendData = JSON.stringify({
    'filename': filename,
    'data': data
  })
  try {

    const response = await fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: sendData
    });
    let json = await response.json();
    return json.url
    // https://matrix-client.matrix.org/_matrix/media/r0/download/matrix.org/fyRMOrdFRVQGEwdPaBfaGnQp

  }
  catch (err) {
    console.log('fetch failed', err);
  }

};

const blobToBase64 = blob => new Promise((resolve, reject) => {
  let reader = new FileReader(blob);
  reader.readAsDataURL(blob);
  reader.onload = () => resolve(reader.result)
  // reader.onload = () => resolve(reader.result.replace(/^data:image\/[a-z]+;base64,/, ""));
  reader.onerror = error => reject(error);

});


export const uploadImage = async (imgSrc) => {
  const files = imgSrc
  let result = ""
  try {
    let base64url = await blobToBase64(files);

    try {
      result = await uploadFile(base64url, files.name);

    } catch (err) {
      console.log('upload failed', err);
    }


  } catch (err) {
    console.log('Convert to base64 failed', err);
  }

  return result;
}


